import { Device } from '@capacitor/device';
import { AppBar, Badge, Button, Divider, IconButton, Link, Menu, MenuItem, Popover, Toolbar, Typography, useMediaQuery } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import GetApp from '@material-ui/icons/GetApp';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlineRounded';
import TranslateIcon from '@material-ui/icons/Translate';
// import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { userApi } from 'api';
import clsx from 'clsx';
import { hasPermission } from 'common/access-control';
import { useConfirmDialog } from 'common/ConfirmDialog';
import ImpersonationRequestDialog from 'common/elements/ImpersonationRequestDialog';
import { useMenu } from 'common/hooks';
// import clientApi from 'api/clientApi';
import { isNonEmpty, multiLang } from 'common/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import NotificationLink from 'views/Notification/NotificationLink';
const PUBLIC_URL = process.env['PUBLIC_URL'];

const EMPTY = [];

// const NavButton = withStyles((theme) => ({
//   root: {
//     color: theme.palette.primary.contrastText,
//     marginLeft: theme.spacing(1),
//     // lineHeight: 1,
//   }
// }))(Button);

// const CustomRouterLink = forwardRef((props, ref) => (
//   <NavLink ref={ref} {...props} />
// ));

// const notifications = [
//   {
//     title: 'Building Master Creation',
//     content: 'Building master record has been created.',
//     module: 'BUILDING_MASTER',
//     moduleItemId: 1,
//     noFurtherAction: true,      //Navigation button
//     dateCreated: '17/04/2020'
//   },
//   {
//     title: 'Building Master Creation',
//     content: 'A New Building Master Creation Request is submitted.',
//     module: 'BUILDING_MASTER',
//     moduleItemId: null,
//     noFurtherAction: false,     // 'Done' button
//     dateCreated: '17/04/2020'
//   }
// ];

// const getModuleNavMap = () => ({
//   TRNX_DATAFEED: {
//     // url: 'buildings',
//     name: 'Transactions'
//   },
//   BUILDING_MASTER_CREATION_REQUEST: {
//     url: 'buildings',
//     name: 'Building'
//   }, 
//   PROPERTY_STOCK: {
//     url: 'properties',
//     name: 'Property Stock'
//   },
//   CLIENT_DORMANCY: {
//     url: 'client-detail', 
//     name: 'Client'
//   },
//   PROPERTY_STOCK_DORMANCY: {
//     url: 'properties',
//     name: 'Property Stock'
//   },
//   CLAIM_CLIENT: {
//     action: (cid) => {
//       
//     },
//     name: 'Client'
//   },
//   CLAIM_PROPERTY_STOCK: {
//     url: 'properties',
//     name: 'Property Stock'
//   },
//   // CLAIM_CLIENT_CONTACT: {
//   //   url: 'client-detail',
//   //   name: 'Client'
//   // }
// });

const useStyles = makeStyles(theme => ({
  root: {
    // boxShadow: 'none',
    background: '#fff',
    color: '#000',
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  navbar: {
    marginLeft: theme.spacing(3),
  },
  activeNavBtn: {
    backgroundColor: theme.palette.primary.dark,
  },
  brand: {
    color: '#183B68',
    display: 'flex', flexDirection: 'row', alignItems: 'center',
    lineHeight: '40px',
    fontWeight: 'bold',
  },
  list: {
    maxHeight: 400,
    minWidth: 300,
    maxWidth: 300,
    overflowY: 'auto'
  },
  notificationHighlight: {
    fontWeight: 800
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { locale, lang, langUser,
  } = useSelector(/** @param {import('reducers').IRootState} state */(state) => state.locale);
  const notificationList = useSelector((state) => state.notification.allList) ?? EMPTY;
  const { iosStatusBarHeight } = useSelector((state) => state.layout);
  const { username, uid, token } = useSelector(state => state.login);
  const handleLangSwitch = (newLocale) => {
    dispatch({ type: 'Locale.SwitchLocale', locale: newLocale });
    userApi.switchUserLocale(uid, newLocale, token);
  }
  const apkLink = useSelector((state) => state.systemSettings.System?.APK_LOCATION.AGENT);
  const appStoreLink = useSelector((state) => state.systemSettings.System?.APP_STORE_LOCATION.AGENT);
  // const DISPLAY_DATE_FORMAT = useSelector((state) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const languageOptions = useSelector((state) => state.systemSettings.Language?.LANGUAGE_OPTION) ?? {};
  const impersonationId = useSelector((state) => state.login.impersonationId ?? '');
  const impersonating = isNonEmpty(impersonationId);
  const impersonateUsername = useSelector((state) => state.login.impersonateUsername ?? '');
  const privileges = useSelector((state) => state.login.privileges ?? {});
  const canImpersonate = hasPermission(privileges, 'UPDATE', 'IMPERSONATION');
  const env = process.env['PUBLIC_URL'];

  const classes = useStyles();

  const [notifications, setNotifications] = useState([]);

  const isSmallScreen = useMediaQuery('(max-width: 400px)');
  useEffect(() => {
    setNotifications(notificationList);
  }, []);

  useEffect(() => {
    setNotifications(notificationList);
  }, [notificationList]);

  const deviceInfo = async () => {
    return await Promise.resolve(Device.getInfo());
  };

  const [displayAPK, setDisplayAPK] = useState(false);

  const [displayIOS, setDisplayIOS] = useState(false);

  deviceInfo().then((info) => {
    if (info.operatingSystem === 'android') {
      setDisplayAPK(true);
    } else if (info.operatingSystem === 'ios'){
      setDisplayIOS(true);
    } else {
      setDisplayIOS(true);
      setDisplayAPK(true);
    }
  });


  // const apkIcon = ()=>
  //   <div><IconButton
  //     onClick={() => { window.open(apkLink); } }>
  //     <img src="images/apkicon2.svg" style={{ height: '1.0em', width: '1.0em' }} />
  //   </IconButton>
  // </div>;
  // const appstoreIcon = ()=>
  //   <div><IconButton
  //     onClick={() => { window.open('google.com'); } }>
  //     <img src="images/appstoreicon.svg" style={{ height: '1.0em', width: '1.0em' }} />
  //   </IconButton>
  // </div>;

  const downloadMenuIcon = () =>
    <div>
      <Button {...downloadMenu.buttonProps()} style={{ minWidth: 'auto' }} startIcon={<GetApp />} color="inherit"></Button>
      <Menu {...downloadMenu.menuProps()}>
        {displayAPK ?
          <MenuItem onClick={() => {
            window.open(apkLink);
          }}>{lang.downloadAPK}</MenuItem> : null}
        {displayIOS ?
          <MenuItem onClick={() => {
            window.open(appStoreLink);
          }}>{lang.downloadIOS}</MenuItem>
          : null}
      </Menu>
    </div>

  const [] = useState({});
  // const createMenuHandler = (subMenu) => {
  //   const id = subMenus.length;

  //   subMenus.push({
  //     items: subMenu,
  //     id,
  //     onClose: () => setSubMenuAnchors({
  //       ...subMenuAnchors,
  //       [id]: null,
  //     }),
  //   });

  //   return (e) => {
  //     setSubMenuAnchors({
  //       ...subMenuAnchors,
  //       [id]: e.target,
  //     })
  //   }
  // };

  const confirmDialog = useConfirmDialog();

  // const menuIconRef = useRef();
  // useEffect(() => {
  //   if (menuIconRef.current) {
  //     setTimeout(() => menuIconRef.current.focus(), 1000);
  //   }
  // }, [ menuIconRef.current ]);
  const langMenu = useMenu();
  const userMenu = useMenu();
  const downloadMenu = useMenu();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // onReadAction();
  };

  // moduleNavMap
  // const moduleNavMap = {
  //   ...getModuleNavMap(),
  //   CLAIM_CLIENT: {
  //     action: (cid) => {
  //       
  //       clientApi.getSummary(cid, token).then(({ error, data }) => {
  //         if (error) {
  //           dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgAlreadyAssignedClient, severity: 'error' } })
  //           return;
  //         }
  //         confirmDialog.confirm(
  //           <div>
  //             <Typography variant="h3">{langClientDetail.titleSummary}</Typography>
  //             {/* <span><b>Client Name: </b>{multiLang(locale, data.chineseName, data.englishName) ?? ''}</span> */}

  //             <div><span><b>{langClientDetail.chineseName}: </b>{data.chineseName}</span></div>
  //             <div><span><b>{langClientDetail.englishName}: </b>{data.englishName}</span></div>

  //             <div><span><b>{langClientDetail.sourceOfClient}: </b>{sourceOfClientOptions[data.source]}</span></div>
  //             <div>
  //               <span>
  //                 <b>{langPropertyStock.district}: </b>
  //                 {data.district.map(d => districtOptions[d]).join(', ')}
  //               </span>
  //             </div>

  //             <div style={{ marginTop: 8 }}>
  //               <span style={{ textAlign: 'right' }}>{langClientDetail.msgConfirmClaimClient}</span>
  //             </div>
  //           </div>, lang.actionYes, lang.actionNo
  //         ).then(confirmed => {
  //           if (confirmed) {
  //             dispatch({ type: 'Client.ClaimRequested', payload: { cid } });
  //           }
  //         });
  //       });

  //     },
  //     name: 'Client'
  //   }
  // }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // const onReadAction = () => {
  // dispatch({ type: 'Notifications.ReadActionRequested', payload: notifications?.map(n => n.id) ?? []});
  // notificationApi.readAction(notifications?.map(n => n.id), token).then(({ data, error }) => {
  //   if(data) {
  //     setNotifications(data);
  //   } else if(error) {
  //     throw new Error(error);
  //   }
  // })
  // }

  const [openImpersonationRequestDialog, setOpenImpersonationRequestDialog] = React.useState(false);
  const handleClickOpenImpersonationRequestDialog = () => {
    setOpenImpersonationRequestDialog(true);
  };

  const handleCloseImpersonationRequestDialog = () => {
    setOpenImpersonationRequestDialog(false);
  };

  return (
    <AppBar
      {...rest}
      style={{ paddingTop: iosStatusBarHeight }}
      className={clsx(classes.root, className)}
    >
      {confirmDialog.render()}
      <ImpersonationRequestDialog
        open={openImpersonationRequestDialog}
        handleClickOpen={handleClickOpenImpersonationRequestDialog}
        handleClose={handleCloseImpersonationRequestDialog}
      />
      <Toolbar>
        {/* <Tooltip title="菜單" aria-label="Menu">
          <IconButton ref={menuIconRef}
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip> */}

        {confirmDialog.render()}

        <RouterLink to="/">
          {/* <Typography h2 className={classes.brand}> */}
          <Typography classkey={"h2"} className={classes.brand}>
          {isSmallScreen ? 
          <img src="images/logo-toolbar-1920.png" style={{ height: 40, marginRight: 16 }} /> :
          <img src="images/logo-toolbar-1920.png" style={{ height: 60, marginRight: 16 }} /> 
          }
            {/* 物業代理系統 */}
          </Typography>
        </RouterLink>



        {/* <Hidden lgUp>
          <div className={classes.navbar}>
            {
              getPages().map(page => (!page.subMenu) ? <NavButton
                component={CustomRouterLink}
                activeClassName={classes.activeNavBtn}
                to={page.href}
                startIcon={page.icon}>
                  {page.title}
              </NavButton> : <NavButton
                onClick={createMenuHandler(page.subMenu)}
                startIcon={page.icon}>
                  {page.title}
              </NavButton>)
            }
          </div>
          {
            subMenus.map((menu) => <Popper
              open={Boolean(subMenuAnchors[menu.id])}
              anchorEl={subMenuAnchors[menu.id]} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={menu.onClose}>
                      <MenuList>
                        {menu.items.map((item) => <MenuItem>{item.title}</MenuItem>)}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>)
          }
        </Hidden> */}

        <div className={classes.flexGrow} />
        {/* <Hidden mdDown> */}

        {downloadMenuIcon()}
        <IconButton
          color="inherit"
          {...langMenu.buttonProps()}
        >
          <TranslateIcon />
        </IconButton>
        <Menu {...langMenu.menuProps()}>
          {/* TODO: tmp disabled zh_CN */}
          {Object.keys(languageOptions).filter(key => key !== 'zh_CN').map(key => {
            return <MenuItem key={key} onClick={() => {
              handleLangSwitch(key);
              langMenu.onClose();
            }}>{languageOptions[key]}</MenuItem>
          })}
        </Menu>

        <IconButton color="inherit" onClick={handleClick}>
          <Badge
            badgeContent={isNonEmpty(notifications) ? notifications[0]['count'] : null}
            color="secondary"
          // onClick={() => handleLangSwitch()}
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List subheader={
            <ListSubheader style={{ background: '#fff' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>{lang.titleNotifications}</div>
                <div>
                  <Link
                    {...{ component: 'a', href: `${PUBLIC_URL}/notifications-centre` }}
                    variant="body2"
                    onClick={(ev) => {
                      ev.preventDefault();
                      history.push(`/notifications-centre`);
                      handleClose();
                    }}
                  >
                    {lang.titleGotoNotificationCenter}
                  </Link>
                </div>
              </div>
            </ListSubheader>} className={classes.list}>
            {
              notifications?.map((n, idx) => (
                <div>
                  <Divider variant="middle" />
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      classes={{
                        primary: n.isRead ? 'inherit' : classes.notificationHighlight,
                        secondary: n.isRead ? 'inherit' : classes.notificationHighlight
                      }}
                      primary={
                        <React.Fragment>
                          {multiLang(locale, n.titleTc, n.titleEn)}
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          {
                            multiLang(locale, n.contentTc, n.contentEn)?.split('\n').map((line, _) =>
                              <React.Fragment key={idx}>
                                {line}
                                <br />
                              </React.Fragment>
                            )
                          }
                          {
                            (n.noFurtherAction && !n.isExpired) ?
                              <NotificationLink notification={n} confirmDialog={confirmDialog} /> : null
                          }
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </div>
              ))
            }
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Link
                {...{ component: 'a', href: `${PUBLIC_URL}/notifications-centre` }}
                variant="body2"
                style={{}}
                onClick={(ev) => {
                  ev.preventDefault();
                  history.push(`/notifications-centre`);
                  handleClose();
                }}
              >
                {isNonEmpty(notifications) ? notifications[0]['count'] > 20 ? lang.captionMore + '...' : null : null}
              </Link>
            </div>
          </List>

        </Popover>
        {/* <IconButton
            className={classes.signOutButton}
            color="inherit"
          >
            <InputIcon />
          </IconButton> */}
        <Button {...userMenu.buttonProps()} startIcon={<PeopleIcon />} color="inherit">{username ?? 'user'}</Button>

        <Menu {...userMenu.menuProps()}>
          <MenuItem onClick={() => {
            history.push(`/users/${uid}`, { isPref: true });
          }}>{lang.actionUserProfile}</MenuItem>
          {canImpersonate ?
            <MenuItem onClick={() => {
              handleClickOpenImpersonationRequestDialog();
            }}>{langUser.actionImpersonationRequest}</MenuItem>
            : null}
          <MenuItem onClick={() => {
            if (impersonating) {
              dispatch({ type: 'User.ImpersonateUserStopImpersonationRequested', payload: { impersonationId: impersonationId, impersonateUsername: impersonateUsername } });
            } else {
              history.replace('/sign-in');
              dispatch({ type: 'Login.LogoutRequested' });
              window.localStorage.setItem('logoutEnv', env);
              window.localStorage.setItem('timestamp', Date.now().toString());

            }
          }}>{lang.actionLogout}</MenuItem>
        </Menu>

        {/* </Hidden> */}
        {/* <Hidden lgUp> */}
        {/* <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton> */}
        {/* </Hidden> */}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
