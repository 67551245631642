import {
  Button as MuiButton, Card, CardContent, CardHeader, Checkbox, Divider, FormControl,
  FormHelperText, Grid, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, Popover, Select, TextField,
  TextFieldProps, Theme, Tooltip, Typography, useMediaQuery, withStyles
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import AddIcon from '@material-ui/icons/Add';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import fileApi from 'api/fileApi';
import clsx from 'clsx';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { PropertyAttachmentDTO, PropertyFileMap, PropertyMultiValueMap, PropertyStockDTO } from 'common/dto';
import MultilineTextField from 'common/elements/MultilineTextField';
import { FormPresenter } from 'common/hooks';
import { HandleFormHelperText, tooltipHoc } from 'common/ui';
import { initFormKeysAndBooleanMap, isNonEmpty, limitNumberFieldLength, limitTextFieldLength, resetFileInput } from 'common/utils';
import moment from 'moment';
import { extname } from 'path';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import theme from 'theme';
import { format } from 'util';
import PropertySummaryReportDialog from 'views/PropertyList/components/PropertySummaryReportDialog';


const Button = tooltipHoc(MuiButton);

const regex = new RegExp(/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);

const NumberField = withStyles({
  root: {
    '& input': {
      textAlign: 'right',
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
},
)((props: TextFieldProps) => {
  return <TextField type={"text"}{...props} inputProps={{
    ...props.inputProps,
    min: 0,
    max: Math.max(),
    inputMode: 'decimal',
    pattern: "([^0-9]*)",
    step: 0.01
  }} {...props}
    onKeyDown={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
      // if (!e.key.match(regex) || (e.key === '.' && String(props.value).indexOf('.') > 0) || (e.key === '.' && String(props.value).length === 0) || e.keyCode === 229 || e.key === 'Unidentified') {

      //   e.preventDefault();

      // }

    }}

    onKeyPress={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
    }}
  />;
});

const disablePrevDates = (startDates: any) => {
  const startSeconds = Date.parse(startDates);
  return (date: any) => {
    return Date.parse(date) < startSeconds;
  }
}

const tmp = () => {
  //form.values.leaseCommencement?.length!==20&&form.values.leaseCommencement?.localeCompare(form.values.leaseExpiry??"")===1?form.values.leaseExpiry="":"";

}

const InputGroup = (props: { children: React.ReactNode[], label?: string }) => {
  const { children } = props;
  return (
    <Grid container /*xs={12}*/ style={{ display: 'flex' }}>
      <Grid
        item
        style={{ flex: 1 }}
      >
        {children[0]}
      </Grid>

      <Grid item style={{ width: 40, flexShrink: 1 }}>
        <TextField
          fullWidth
          InputProps={{
            style: { borderRadius: 0, borderLeft: 0, borderRight: 0 },
          }}
          inputProps={{
            style: { paddingLeft: 0, paddingRight: 0, textAlign: 'center', borderLeft: 0, borderRight: 0 },
          }}
          disabled value="-"
          variant="outlined"
          margin="dense" />
      </Grid>

      <Grid
        item
        style={{ flex: 1 }}
      // md={5} sm={5}
      // xs={5}
      >
        {children[1]}
      </Grid>
    </Grid>
  );
}

InputGroup.leftItem = () => ({ style: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } });
InputGroup.rightItem = () => ({ style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } });

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  multiLangInputRoot: {
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.grey[400],
    borderWidth: 1,
    borderStyle: 'solid',
    // paddingLeft: theme.spacing(1)
  },
  langAdornment: {
    paddingLeft: theme.spacing(1),
  },
  fileItem: {
    '& .fileControl': {
      display: 'none',
    },
    '&:hover .fileControl': {
      display: 'inherit',
    },
  }
}));

interface PropertyFeaturesProps extends CardProps {
  form: FormPresenter<PropertyStockDTO>;
  tagForm: FormPresenter<PropertyMultiValueMap>;
  fileForm: FormPresenter<PropertyFileMap>;
  unsaved: boolean;
  onSave?: (noNavigate?: boolean) => void;
  saveDisabled: boolean;
  isNew?: boolean;
}



const PropertyFeatures = (props: PropertyFeaturesProps) => {
  const { className, form, tagForm, fileForm, unsaved, onSave, saveDisabled, isNew, ...rest } = props;
  const {
    locale, decoOptions, otherFeatureOptions, viewOptions, facingOptions,
    langPropertyStock, lang, otherOptions,
    primarySchoolNetOptions, secondarySchoolNetOptions, clubHouseFacilitiesOptions,
  } = useSelector((state: IRootState) => state.locale);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const { iosStatusBarHeight } = useSelector((state: IRootState) => state.layout);
  const classes = useStyles();

  const bind = form.bind;

  const isXScreen = useMediaQuery('(max-width: 370px)');
  const isSmallScreen = useMediaQuery('(max-width: 430px)');
  const isEn = locale === "en"

  type Options = { [key: string]: string };
  const multiSelectField = (key: keyof typeof tagForm.values, label: string, options: Options) => {
    return <React.Fragment>
      {/* <Typography>{label}</Typography> */}
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel id={`${label}-label`} style={{ background: '#fff' }}>{label}</InputLabel>
        <Select
          multiple
          {...tagForm.bind(key)}
          renderValue={(selected) => (selected as string[]).map(key => options?.[key] ?? key).join(', ')}
        >
          {Object.keys(options ?? {}).map(val => (
            <MenuItem key={val} value={val}>
              <Checkbox checked={tagForm.values[key]!.indexOf(val) > -1} />
              <ListItemText primary={options[val]} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  };

  const dispatch = useDispatch() as PASDispatch;
  const propertyStockSettings = useSelector((state: IRootState) =>
    state.systemSettings?.PropertyStock) ?? {};
  const uploadPolicies: { [k: string]: any } = {
    PHOTO: propertyStockSettings['PHOTO_UPLOAD_POLICY'],
    FLOOR_PLAN: propertyStockSettings['FLOOR_PLAN_UPLOAD_POLICY'],
  };
  const uploadLimits: { [k: string]: number } = {
    PHOTO: +(uploadPolicies.PHOTO.MAX_NUM_OF_FILE) ?? 0,
    FLOOR_PLAN: +(uploadPolicies.FLOOR_PLAN.MAX_NUM_OF_FILE) ?? 0,
  }
  const photos = fileForm.values.PHOTO?.filter(attachment => attachment.dataStatus !== 'I') ?? [];
  const setPhotos = (newList: PropertyAttachmentDTO[]) => fileForm.updateValues('PHOTO', newList);
  const floorPlans = fileForm.values.FLOOR_PLAN?.filter(attachment => attachment.dataStatus !== 'I') ?? [];
  const setFloorPlans = (newList: PropertyAttachmentDTO[]) => fileForm.updateValues('FLOOR_PLAN', newList);

  const addBalcony = () => {
    let sizeList = [''];
    if ((form.values['balconySizes']?.length ?? 0) == 0) {
      sizeList.push(''); //if user has not input the first balcony size, add dummy value for it
    }
    form.updateValues('balconySizes', [...form.values['balconySizes'], ...sizeList]);
  }

  const delBalcony = () => {
    let sizeList = [...form.values['balconySizes']];
    if ((form.values['balconySizes']?.length ?? 0) > 0) {
      sizeList.pop();
      form.updateValues('balconySizes', [...sizeList]);
    }
  }

  const handleUpload = (type: keyof PropertyFileMap) => (ev: React.ChangeEvent<HTMLInputElement>) => {
    let files = Array.from(ev.target.files ?? []);
    resetFileInput(ev);
    const currentLength = fileForm.values[type]?.filter(attachment => attachment.dataStatus !== 'I')?.length ?? 0;

    const uploadPolicy = uploadPolicies[type as any];
    const fileAccept = uploadPolicy.SUPPORT_FILE_TYPE?.split(',').map((str: string) => `.${str.trim().toUpperCase()}`);
    const uploadLimit = +(uploadPolicy.MAX_NUM_OF_FILE) ?? 0;
    const sizeLimit = (+(uploadPolicy.MAX_FILE_SIZE) * 1024 * 1024) ?? 0;

    let remainLength = uploadLimit - currentLength > 0 ? uploadLimit - currentLength : 0;
    if (remainLength === 0) return;

    const hasUnsupportedFileFormats = files.map(f => f.name)
      .map(extname)
      .filter(ext => fileAccept.indexOf(ext.toUpperCase()) < 0)
      ;

    if (hasUnsupportedFileFormats.length > 0) {
      dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: format(lang.msgUnsupportedFileFormat, hasUnsupportedFileFormats.toString()) } })
      return;
    }

    const hasLimitExceededFiles = files.some(f => f.size > sizeLimit);

    if (hasLimitExceededFiles) {
      dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgFileSizeExceed } });
      return;
    }

    Promise.all(files.slice(0, remainLength).map(file => {
      return fileApi.addFile(file, true).then(result => result.data as string)
    })).then(paths => {
      // paths = paths.filter(path => fileForm.values[type]!.map(f => f.filename).indexOf(path) < 0);
      fileForm.updateValues(type, [
        ...fileForm.values[type]!,
        ...paths.map(path => ({ filename: path, type, uploadDate: (new Date).toISOString(), remarks: '' }))
      ]);
    });
  }

  const numberOptions = () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(String).map((v, idx) => <MenuItem key={idx} value={v}>{v}</MenuItem>);

  // upload req popup
  const [reqPopupOpened, setReqPopupOpened] = useState<{ [key: string]: boolean }>({});
  const [popupAnchor, setPopAnchor] = useState<HTMLAnchorElement>();
  // const refPhotoUploadRequirement = React.createRef<HTMLAnchorElement>();
  const openPopup = (key: string) => (ev: React.MouseEvent<HTMLAnchorElement>) => {
    setPopAnchor(ev.currentTarget);
    setReqPopupOpened(prev => ({ ...prev, [key]: true }));
  };
  const closePopup = (key: string) => () => setReqPopupOpened(prev => ({ ...prev, [key]: false }));
  const confirmDialog = useConfirmDialog();

  const [photoLightBoxOpened, setPhotoLightBoxOpened] = useState(false);
  const [photoLightBoxIndex, setPhotoLightBoxIndex] = useState(0);
  const [floorPlanLightBoxOpened, setFloorPlanLightBoxOpened] = useState(false);
  const [floorPlanLightBoxIndex, setFloorPlanLightBoxIndex] = useState(0);

  const [openPropertySummaryDialog, setOpenPropertySummaryDialog] = useState(false);

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(form));
  }, [Object.keys(form.values).length])


  return (<Grid container /*xs={12}*/ spacing={1}>
    <Grid item xs={12}>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        {confirmDialog.render()}

        {photoLightBoxOpened &&
          <Lightbox
            reactModalStyle={{ overlay: { zIndex: 1500, top: iosStatusBarHeight } }}
            mainSrc={`${BASE_URL}/files/${photos[photoLightBoxIndex].filename}`}
            nextSrc={`${BASE_URL}/files/${photos[(photoLightBoxIndex + 1) % photos.length].filename}`}
            prevSrc={`${BASE_URL}/files/${photos[(photoLightBoxIndex + photos.length - 1) % photos.length].filename}`}
            onCloseRequest={() => setPhotoLightBoxOpened(false)}
            onMovePrevRequest={() => setPhotoLightBoxIndex((photoLightBoxIndex + photos.length - 1) % photos.length)}
            onMoveNextRequest={() => setPhotoLightBoxIndex((photoLightBoxIndex + 1) % photos.length)}
          />
        }

        {floorPlanLightBoxOpened &&
          <Lightbox
            reactModalStyle={{ overlay: { zIndex: 1500, top: iosStatusBarHeight } }}
            mainSrc={`${BASE_URL}/files/${floorPlans[floorPlanLightBoxIndex].filename}`}
            nextSrc={`${BASE_URL}/files/${floorPlans[(floorPlanLightBoxIndex + 1) % floorPlans.length].filename}`}
            prevSrc={`${BASE_URL}/files/${floorPlans[(floorPlanLightBoxIndex + floorPlans.length - 1) % floorPlans.length].filename}`}
            onCloseRequest={() => setFloorPlanLightBoxOpened(false)}
            onMovePrevRequest={() => setFloorPlanLightBoxIndex((floorPlanLightBoxIndex + floorPlans.length - 1) % floorPlans.length)}
            onMoveNextRequest={() => setFloorPlanLightBoxIndex((floorPlanLightBoxIndex + 1) % floorPlans.length)}
          />
        }

        <PropertySummaryReportDialog open={openPropertySummaryDialog} handleClose={() => setOpenPropertySummaryDialog(false)} propertyNoList={form.values.propertyNo ?? ''} />

        <form
          autoComplete="off"
          noValidate
        >
          <CardContent>
            <Grid
              container
              spacing={1}
            >
              <Grid item container md={12} sm={12} xs={12} spacing={1}>
                <Grid
                  item
                  md={2} sm={4}
                  xs={6}
                >
                  {/* <Typography>{langPropertyStock.facing}</Typography> */}

                  <TextField select
                    label={langPropertyStock.facing}
                    fullWidth
                    margin="dense"
                    {...bind('facing')}
                    variant="outlined">
                    <MenuItem key={-1} value={undefined}><option aria-label="None" value="" /></MenuItem>
                    {Object.keys(facingOptions).map((key, idx) => <MenuItem key={idx} value={key}>{facingOptions[key]}</MenuItem>)
                    }
                  </TextField>
                </Grid>

                <Grid
                  item
                  md={2} sm={4}
                  xs={6}
                >
                  {/* <Typography>{langPropertyStock.deco}</Typography> */}
                  <TextField select
                    label={langPropertyStock.deco}
                    fullWidth
                    margin="dense"
                    {...bind('deco')}
                    variant="outlined">
                    <MenuItem key={-1} value={undefined}><option aria-label="None" value="" /></MenuItem>
                    {
                      Object.keys(decoOptions).map((opt, idx) => <MenuItem value={opt} key={idx}>{decoOptions[opt]}</MenuItem>)
                    }
                  </TextField>
                </Grid>

                <Grid item md={2} sm={4} xs={12}>
                  {multiSelectField('VIEW', langPropertyStock.view, viewOptions)}
                </Grid>

                <Grid item md={3} sm={4} xs={12}>
                  {multiSelectField('OTHER_FEATURES', langPropertyStock.otherFeature, otherFeatureOptions)}
                </Grid>

                <Grid item md={3} sm={4} xs={12}>
                  {multiSelectField('CLUB_HOUSE_FACILITIES', langPropertyStock.clubHouseFacilities, clubHouseFacilitiesOptions)}
                </Grid>
              </Grid>
              <Grid item container md={12} sm={12} xs={12} spacing={1}>
                <Grid
                  item
                  md={2} sm={2}
                  xs={6}
                >

                  {/* <Typography>{langPropertyStock.room}</Typography> */}
                  <TextField
                    label={langPropertyStock.room}
                    fullWidth
                    // label="房間數"
                    margin="dense"
                    select
                    {...bind('room')}
                    variant="outlined"
                  >
                    {/* <MenuItem key={-1} value={undefined}><option aria-label="None" value="" /></MenuItem> */}
                    {numberOptions()}</TextField>
                </Grid>

                <Grid
                  item
                  md={2} sm={2}
                  xs={6}
                >
                  {/* <Typography>{langPropertyStock.suite}</Typography> */}
                  <TextField
                    label={langPropertyStock.suite}
                    fullWidth
                    margin="dense"
                    name="suite"
                    select
                    variant="outlined"
                    {...bind('suite')}
                  >
                    {/* <MenuItem key={-1} value={undefined}><option aria-label="None" value="" /></MenuItem> */}
                    {numberOptions()}</TextField>
                </Grid>

                <Grid
                  item
                  md={2} sm={2}
                  xs={6}
                >
                  {/* <Typography>{langPropertyStock.bathroom}</Typography> */}
                  <TextField
                    label={langPropertyStock.bathroom}
                    fullWidth
                    margin="dense"
                    select
                    variant="outlined"
                    {...bind('bathroom')}
                  >
                    {/* <MenuItem key={-1} value={undefined}><option aria-label="None" value="" /></MenuItem> */}
                    {numberOptions()}</TextField>
                </Grid>
                {
                  form.values.usage == 'S' ?
                    <Grid item md={2} sm={2} xs={6}>
                      {/* <Typography>{langPropertyStock.toilet}</Typography> */}
                      <TextField
                        label={langPropertyStock.toilet}
                        fullWidth
                        margin="dense"
                        select
                        {...bind('toilet')}
                        variant="outlined"
                      >
                        {/* <MenuItem key={-1} value={undefined}><option aria-label="None" value="" /></MenuItem> */}
                        {numberOptions()}</TextField>
                    </Grid> : null
                }

                <Grid
                  item
                  md={2} sm={2}
                  xs={6}
                >
                  {/* <Typography>{langPropertyStock.helperRoom}</Typography> */}
                  <TextField
                    fullWidth
                    label={langPropertyStock.helperRoom}
                    // label="工人房"
                    margin="dense"
                    select
                    variant="outlined"
                    {...bind('helperRoom')}
                  >
                    {/* <MenuItem key={-1} value={undefined}><option aria-label="None" value="" /></MenuItem> */}
                    {numberOptions()}</TextField>
                </Grid>
                <Grid
                  item
                  md={1} sm={1}
                  xs={undefined}
                ></Grid>
                {/* <Grid item md={3} sm={3} xs={12}>
              {multiSelectField('LIVING_ROOM', langPropertyStock.livingRoom, livingRoomOptions)}
              </Grid> */}
              </Grid>


              {/* <Grid item md={1} sm={2} xs={3}> */}
              {/* <Typography>{langPropertyStock.garden}</Typography> */}
              {/* <Switch onChange={(_, val) => form.updateValues('hasGarden', val)} checked={form.values.hasGarden} /> */}
              {/* <FormGroup row>
              <FormControlLabel
                  control={
                    <Switch onChange={(_, val) => form.updateValues('hasGarden', val)} checked={form.values.hasGarden} />
                  }
                  label={langPropertyStock.garden}
                />
              </FormGroup>
              </Grid> */}
              <Grid item container md={12} sm={12} xs={12} spacing={1}>
                <Grid item md={2} sm={2} xs={3}>
                  {/* <Typography>{langPropertyStock.gardenArea}</Typography> */}
                  <NumberField
                    label={langPropertyStock.garden}
                    // disabled={!form.values.hasGarden}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...bind('gardenArea')}
                    onChange={(e) => {

                      // if (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2) {
                      //   e.preventDefault();
                      // }
                      // else {
                      //   form.updateValues("gardenArea", e.target.value)
                      // }
                      limitNumberFieldLength(e, 'gardenArea', form);
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{lang.uFt}</InputAdornment>
                    }}
                  />
                </Grid>

                {/* <Grid item md={1} sm={2} xs={3}> */}
                {/* <Typography>{langPropertyStock.rooftop}</Typography> */}
                {/* <Switch onChange={(_, val) => form.updateValues('hasRooftop', val)} checked={form.values.hasRooftop} /> */}
                {/* <FormGroup row>
              <FormControlLabel
                  control={
                    <Switch onChange={(_, val) => form.updateValues('hasRooftop', val)} checked={form.values.hasRooftop} />
                  }
                  label={langPropertyStock.rooftop}
                />
              </FormGroup>
              </Grid> */}

                <Grid item md={2} sm={2} xs={3}>
                  {/* <Typography>{langPropertyStock.rooftopArea}</Typography> */}
                  <NumberField
                    label={langPropertyStock.rooftop}
                    // disabled={!form.values.hasRooftop}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...bind('rooftopArea')}
                    onChange={(e) => {

                      // if (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2) {
                      //   e.preventDefault();
                      // }
                      // else {
                      //   form.updateValues("rooftopArea", e.target.value)
                      // }
                      limitNumberFieldLength(e, 'rooftopArea', form);
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{lang.uFt}</InputAdornment>
                    }}
                  />
                </Grid>

                <Grid
                  item
                  md={2} sm={2}
                  xs={3}
                  style={{ display: 'none' }}
                >
                  {/* <Typography>{langPropertyStock.balcony}</Typography> */}
                  <TextField
                    label={langPropertyStock.balcony}
                    fullWidth
                    margin="dense"
                    select
                    {...bind('balcony')}
                    // onChange={(e) => {
                    //   console.log("youareclcik");
                    //   if (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2) {
                    //     e.preventDefault();
                    //   }
                    //   else {
                    //     form.updateValues("balcony", e.target.value)
                    //   }
                    // }}

                    variant="outlined"
                  >{numberOptions()}</TextField>
                </Grid>

                {/* ---------- balcony size, for zero or one balcony --------- */}
                <Grid
                  item
                  md={2} sm={2}
                  xs={3}
                >
                  <NumberField
                    label={langPropertyStock.balcony}
                    fullWidth
                    margin="dense"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{lang.uFt}</InputAdornment>,
                      disabled: saveDisabled,
                    }}
                    variant="outlined"
                    value={form.values.balconySizes?.[0] ?? ''}
                    onChange={(e) => {

                      //limitNumberFieldLength(e, 'balcony', form);
                      if (isNaN(parseFloat(e.target.value))) {
                        e.preventDefault();
                      } else if (e.target.value.match(/^\d*\.?\d*$/) == null) {
                        e.preventDefault();
                      } else {
                        form.updateValues('balconySizes',
                          (form.values['balconySizes']?.length ?? 0) == 0 ? [e.target.value] :
                            (form.values['balconySizes'] ?? []).map((_, idx) => {
                              const current = form.values.balconySizes?.[idx] ?? '';
                              return 0 === idx ? (!isNonEmpty(e.target.value) || isNaN(parseFloat(e.target.value)) || e.target.value.match(/^\d*\.?\d*$/) == null) ? '' : (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2) ? current : e.target.value : current;
                            })
                        );
                      }
                    }
                    }
                  />

                  {/* value={form.values.balconySizes?.[i] ?? ''}
                    onChange={(e) => {
                      // form.updateValues('balconySizes', e.target.value.join(','))
                      form.updateValues('balconySizes', [...range(0, form.values.balcony ?? 0)].map(idx => {
                        const current = form.values.balconySizes?.[idx] ?? ''; */}

                </Grid>

                {/* ------------ list of balconly size, for more than one balony --------------- */}
                {
                  (form.values['balconySizes'] ?? []).map((_, i) => i >= 1 && <Grid
                    item
                    md={2} sm={2}
                    xs={3}
                  >
                    {/* <Typography>{langPropertyStock.balconyArea}</Typography> */}
                    <NumberField
                      label={langPropertyStock.balcony}
                      fullWidth
                      margin="dense"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{lang.uFt}</InputAdornment>,
                        disabled: saveDisabled,
                      }}
                      variant="outlined"
                      value={form.values.balconySizes?.[i] ?? ''}
                      onChange={(e) => {
                        if (isNaN(parseFloat(e.target.value))) {
                          e.preventDefault();
                        } else if (e.target.value.match(/^\d*\.?\d*$/) == null) {
                          e.preventDefault();
                        } else {
                          form.updateValues('balconySizes', (form.values['balconySizes'] ?? []).map((_, idx) => {
                            const current = form.values.balconySizes?.[idx] ?? '';
                            return i === idx ? !isNonEmpty(e.target.value) ? '' : (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2) ? current : e.target.value : current;
                          }));
                        }
                      }
                      }
                    />
                  </Grid>)
                }

                <Tooltip title={lang.actionAdd}>
                  <IconButton color="primary" onClick={addBalcony} disabled={saveDisabled}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              {/* <Tooltip title={lang.actionDelete}>
                <IconButton color="primary" onClick={delBalcony} disabled={saveDisabled}>
                  <RemoveIcon />
                </IconButton>
              </Tooltip> */}
              <Grid item container md={12} sm={12} xs={12} spacing={1}>
                <Grid item md={4} sm={4} xs={12}>
                  {multiSelectField('OTHERS', langPropertyStock.others, otherOptions)}
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                  {/* <Typography>{langPropertyStock.primarySchoolNet}</Typography> */}
                  <TextField
                    inputProps={{ readOnly: true }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled
                    label={langPropertyStock.primarySchoolNet}
                    select
                    // value={form.values.primarySchoolNet}
                    {...bind('primarySchoolNet')}
                  >
                    <MenuItem key={-1} value={undefined}><option aria-label="None" value="" /></MenuItem>
                    {Object.keys(primarySchoolNetOptions).map((val: string) => (
                      <MenuItem key={val} value={val}>
                        {primarySchoolNetOptions[val]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={4} sm={4} xs={12}>
                  {/* <Typography>{langPropertyStock.secondarySchoolNet}</Typography> */}
                  <TextField
                    inputProps={{ readOnly: true }}
                    select
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    label={langPropertyStock.secondarySchoolNet}
                    disabled
                    // value={form.values.secondarySchoolNet}
                    {...bind('secondarySchoolNet')}
                  > <MenuItem key={-1} value={undefined}><option aria-label="None" value="" /></MenuItem>
                    {Object.keys(secondarySchoolNetOptions).map((val: string) => (
                      <MenuItem key={val} value={val}>
                        {secondarySchoolNetOptions[val]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item container md={12} sm={12} xs={12} spacing={1}>
                <Grid
                  item
                  md={4} sm={8}
                  xs={12}
                >

                  {/* <TextField
                    fullWidth
                    label={langPropertyStock.leaseCommencement}
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                      ...InputGroup.leftItem()
                      // startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    {...bind('leaseCommencement')}
                  /> */}
                  <InputGroup>
                    <KeyboardDatePicker autoOk={true}
                      InputProps={{ ...InputGroup.leftItem() }}
                      //disableToolbar
                      fullWidth
                      variant="inline"
                      inputVariant="outlined"
                      format={DISPLAY_DATE_FORMAT}
                      label={langPropertyStock.leaseCommencement}
                      margin="dense"
                      disabled={form.isDisabled('leaseCommencement')}
                      // inputValue={form.values.leaseCommencement ?? ''}
                      value={form.values.leaseCommencement ? moment(form.values.leaseCommencement) : null}
                      onChange={(value) => {
                        form.setValues({
                          ...form.values,
                          leaseCommencement: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined,
                          leaseExpiry: value ? null : form.values.leaseExpiry
                        });

                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />

                    {/* {form.values.leaseCommencement?.length!==20&&form.values.leaseCommencement?.localeCompare(form.values.leaseExpiry??"")===1?form.values.leaseExpiry="":form.values.leaseExpiry=""} */}

                    <KeyboardDatePicker autoOk={true}
                      InputProps={{ ...InputGroup.rightItem() }}
                      //disableToolbar
                      fullWidth
                      variant="inline"
                      inputVariant="outlined"
                      format={DISPLAY_DATE_FORMAT}
                      disabled={form.isDisabled('leaseExpiry')}
                      label={langPropertyStock.leaseExpiry}
                      margin="dense"
                      shouldDisableDate={disablePrevDates(form.values.leaseCommencement)}
                      // inputValue={form.values.leaseExpiry ?? ''}
                      value={form.values.leaseExpiry ? moment(form.values.leaseExpiry) : null}
                      onChange={(value) => {
                        form.setValues({
                          ...form.values,
                          leaseExpiry: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                        });
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />

                  </InputGroup>
                </Grid>

                <Grid
                  item
                  md={2} sm={4}
                  xs={6}
                >
                  <NumberField
                    fullWidth
                    label={langPropertyStock.currentRent}
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    {...bind('currentRent')}
                    onChange={(e) => {
                      // if (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2) {
                      //   e.preventDefault();
                      // }
                      // else {
                      //   form.updateValues("currentRent", e.target.value)
                      // }

                      // This field is a integer 
                      if (!isNonEmpty(e.target.value)) {
                        form.updateValues('currentRent', '');
                      } else if (isNaN(parseFloat(e.target.value))) {
                        e.preventDefault();
                      } else if (e.target.value.indexOf('.') != -1) {
                        e.preventDefault()
                      } else {
                        e.target.value.length > 12 ? e.preventDefault() : form.updateValues('currentRent', parseInt(e.target.value))
                      }
                    }}
                  />
                </Grid>
              </Grid>
              {
                form.values.usage == 'S' ?
                  <Grid item container md={12} sm={12} xs={12} spacing={1}>
                    <Grid item md={1} sm={2} xs={3}>
                      <TextField
                        label={langPropertyStock.backDoor}
                        disabled={!(form.values.usage == 'S')}
                        fullWidth
                        margin="dense"
                        select
                        {...bind('backDoor')}
                        variant="outlined"
                      >
                        {/* <MenuItem key={-1} value={undefined}><option aria-label="None" value="" /></MenuItem> */}
                        {numberOptions()}</TextField>
                    </Grid>

                    <Grid item md={2} sm={2} xs={3}>
                      <NumberField
                        label={langPropertyStock.mezzanineFloorArea}
                        disabled={!(form.values.usage == 'S')}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        {...bind('mezzanineFloorArea')}
                        onChange={(e) => {
                          limitNumberFieldLength(e, 'mezzanineFloorArea', form);
                          // if (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2) {
                          //   e.preventDefault();
                          // }
                          // else {
                          //   form.updateValues("mezzanineFloorArea", e.target.value)
                          // }
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{lang.uFt}</InputAdornment>
                        }}
                      />
                    </Grid>

                    <Grid item md={2} sm={2} xs={6}>
                      <NumberField
                        label={langPropertyStock.storeFrontWidth}
                        disabled={!(form.values.usage == 'S')}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        {...bind('storeFrontWidth')}
                        onChange={(e) => {
                          limitNumberFieldLength(e, 'storeFrontWidth', form);
                          // if (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2) {
                          //   e.preventDefault();
                          // }
                          // else {
                          //   form.updateValues("storeFrontWidth", e.target.value)
                          // }
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{lang.uFt}</InputAdornment>
                        }}
                      />
                    </Grid>

                    <Grid item md={2} sm={2} xs={6}>
                      <NumberField
                        label={langPropertyStock.storeFrontHeight}
                        disabled={!(form.values.usage == 'S')}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        {...bind('storeFrontHeight')}
                        onChange={(e) => {
                          limitNumberFieldLength(e, 'storeFrontHeight', form);
                          // if (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2) {
                          //   e.preventDefault();
                          // }
                          // else {
                          //   form.updateValues("storeFrontHeight", e.target.value)
                          // }
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{lang.uFt}</InputAdornment>
                        }}
                      />
                    </Grid>

                    <Grid item md={2} sm={2} xs={6}>
                      <NumberField
                        label={langPropertyStock.shopDepth}
                        disabled={!(form.values.usage == 'S')}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        {...bind('shopDepth')}
                        onChange={(e) => {
                          limitNumberFieldLength(e, 'shopDepth', form);
                          // if (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2) {
                          //   e.preventDefault();
                          // }
                          // else {
                          //   form.updateValues("shopDepth", e.target.value)
                          // }
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{lang.uFt}</InputAdornment>
                        }}
                      />
                    </Grid>
                  </Grid> : null
              }
              <Grid
                item
                md={(form.values.showPrivateRemarks || isNew) ? 6 : 12} sm={12}
                xs={12}
              >
                <Typography>{langPropertyStock.remarks}</Typography>
                <MultilineTextField
                  fullWidth
                  multiline rows="6"
                  margin="dense"
                  {...bind('remarks')}
                  variant="outlined"
                  inputProps={{
                    style: { resize: 'vertical' }
                  }}
                  textLimit={30000}

                // onChange={(e) => {
                //   setKeyBooleanMap(keyBooleanMap.set("remarks", e.target.value.length === 30000))
                // }}

                />





              </Grid>

              {(form.values.showPrivateRemarks || isNew) ?
                <Grid
                  item
                  md={(form.values.showPrivateRemarks || isNew) ? 6 : 12} sm={12}
                  xs={12}
                >
                  <Typography>{langPropertyStock.privateRemarks}</Typography>
                  <MultilineTextField
                    fullWidth
                    multiline rows="6"
                    margin="dense"
                    {...bind('privateRemarks')}

                    variant="outlined"
                    inputProps={{
                      style: { resize: 'vertical' }

                    }}
                    textLimit={30000}

                  />
                  {/* <HandleFormHelperText
                    isError={keyBooleanMap.get('privateRemarks')}
                    errorMessage={lang.textOverLimit}
                  /> */}

                </Grid> : null}

              <Grid item md={12} sm={12} xs={12}>
                <Typography>{langPropertyStock.video}</Typography>
                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  type='url'
                  {...bind('videoLink')}
                  onChange={(e) => {
                    limitTextFieldLength(e, 30000, 'videoLink', form, keyBooleanMap, setKeyBooleanMap);
                  }}
                />
                <HandleFormHelperText
                  isError={keyBooleanMap.get('videoLink')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>



              <Grid item md={12} sm={12} xs={12}>
                <Typography>{langPropertyStock.photo}</Typography>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControl margin="dense">

                    <input
                      style={{ display: 'none' }}
                      id="input-file-photo"
                      multiple
                      type="file"
                      onChange={handleUpload('PHOTO')}
                    />
                    <label htmlFor={photos.length >= uploadLimits.PHOTO || saveDisabled ? undefined : "input-file-photo"}>
                      <Button {...{ component: "span" } as any} variant="contained"
                        tooltip={photos.length >= uploadLimits.PHOTO ? langPropertyStock.msgPhotoLimit.replace('%d', uploadLimits.PHOTO.toString()) : undefined}
                        disabled={photos.length >= uploadLimits.PHOTO || saveDisabled}
                        color="primary">{lang.actionUpload} ({`${photos.length}/${uploadLimits.PHOTO}`})</Button>
                    </label>

                  </FormControl>
                  <Typography style={{ marginLeft: (isSmallScreen && isEn) ? isXScreen ? 84 : 30 : 8 }}>
                    <a onClick={openPopup('PHOTO')} href="javascript:">{langPropertyStock.msgUploadRequirement}</a>
                  </Typography>

                  <Popover
                    open={reqPopupOpened.PHOTO}
                    anchorEl={popupAnchor}
                    onClose={closePopup('PHOTO')}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Typography style={{ margin: 8 }}>{format(langPropertyStock.msgUploadRequirementTemplate,
                      uploadPolicies['PHOTO'].MAX_FILE_SIZE,
                      uploadPolicies['PHOTO'].SUPPORT_FILE_TYPE,
                      uploadPolicies['PHOTO'].RATIO,
                    )}. {langPropertyStock.msgPhotoHint}</Typography>
                  </Popover>
                  <div style={{ paddingLeft: 16 }}>
                    <Button disabled={isNew} onClick={() => {
                      if (unsaved) {
                        confirmDialog.confirm(langPropertyStock.msgPleaseSaveBeforeGenerateSummary, lang.actionConfirm, lang.actionCancel).then(confirmed => {
                          if (confirmed) {
                            onSave?.(true);
                            setOpenPropertySummaryDialog(true);
                          }
                        });
                      } else {
                        setOpenPropertySummaryDialog(true);
                      }
                    }} variant="outlined" color="secondary">
                      {langPropertyStock.actionGeneratePropertySummaryReport}
                    </Button>
                  </div>

                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FormHelperText style={{ color: '#daa520' }}>{langPropertyStock.msgFirst4PhotoForPropertySummary}</FormHelperText>
                </div>

                <div style={{ marginTop: 8, width: '100%', height: 160, background: '#fafafa', borderRadius: 5, overflowX: 'scroll', overflowY: 'hidden' }}>
                  <DragDropContext onDragEnd={(result) => {
                    if (!result.destination) {
                      return;
                    }

                    const i1 = result.source.index, i = result.destination?.index ?? 0;
                    // const newList = [ ...photos ];
                    // const tmp = newList[i];
                    // newList[i] = newList[i1];
                    // newList[i1] = tmp;
                    const oldList = [...photos];
                    let newList = [];
                    if (i > i1) { //move downward
                      newList.push(...oldList.slice(0, i1)); // items after the selected item's original position
                      newList.push(...oldList.slice(i1 + 1, i + 1)); //items between the original position and the destination
                      newList.push(oldList[i1]); // selected item
                      newList.push(...oldList.slice(i + 1, oldList.length)); //items before the destination
                    } else if (i < i1) { //move upward
                      newList.push(...oldList.slice(0, i)); // items after the selected item's destination
                      newList.push(oldList[i1]); //selected item
                      newList.push(...oldList.slice(i, i1)); //items between the destination and the original position
                      newList.push(...oldList.slice(i1 + 1, oldList.length)); // items before the original position
                    } else {
                      newList = oldList;
                    }
                    setPhotos(newList);
                  }}>
                    <Droppable droppableId="photo-container" direction="horizontal">
                      {(provided, _snapshot) => <div ref={provided.innerRef} style={{ width: 138 * photos.length, height: '100%', display: 'flex', alignItems: 'center'}}>
                        {photos.map(({ filename }) => `${BASE_URL}/files/${filename}`).map((src, i) => (
                          <Draggable key={src} draggableId={src} index={i} isDragDisabled={saveDisabled}>
                            {(provided, _snapshot) => (
                              <div className={classes.fileItem}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() => {
                                  // window.open(src, 'Download');
                                  setPhotoLightBoxOpened(true);
                                  setPhotoLightBoxIndex(i);
                                }}
                                style={{
                                  display: 'flex',
                                  cursor: 'pointer',
                                  justifyContent: 'space-between',
                                  alignItems: 'start', marginLeft: 8, width: 260 / 2, minWidth: 260 / 2, height: 130,
                                  backgroundImage: `url("${src}")`, backgroundSize: 'cover', borderRadius: 5,
                                  borderStyle: (i < 4 ? 'solid' : 'none'),
                                  borderColor: (i < 4 ? '#daa520' : 'transparent'),
                                  boxShadow: theme.shadows[5],
                                  ...provided.draggableProps.style,
                                }}
                              >
                                {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'start' }}> */}
                                {saveDisabled ? null : <div
                                  onClick={(ev) => {
                                    ev.stopPropagation();
                                    ev.preventDefault();
                                    const newList = [...photos];
                                    newList.unshift(newList.splice(i, 1)[0]);
                                    setPhotos(newList);
                                  }}
                                  style={{ userSelect: 'none', cursor: 'pointer', display: 'inline-block', background: 'rgba(0,0,0,0.8)', color: '#fff', padding: 4, margin: 4, borderRadius: 8, fontSize: theme.typography.caption.fontSize }}>
                                  {i === 0 ? langPropertyStock.captionCover : langPropertyStock.actionSetCover}
                                </div>}

                                {saveDisabled ? null : <div
                                  onClick={(ev) => {
                                    ev.stopPropagation();
                                    ev.preventDefault();
                                    const newList = [...photos];
                                    newList.splice(i, 1);
                                    confirmDialog.confirm(lang.msgConfirmDelete, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
                                      if (confirmed) {
                                        setPhotos(newList);
                                      }
                                    });
                                  }}
                                  style={{ width: 23, height: 23, fontSize: 21, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontWeight: 'bolder', userSelect: 'none', cursor: 'pointer', background: 'rgba(200,0,0,0.8)', color: '#fff', padding: 4, margin: 4, borderRadius: 800 }}>
                                  <div>{'\u2013'}</div>
                                </div>}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>}
                    </Droppable>
                  </DragDropContext>
                </div>
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <Typography>{langPropertyStock.floorPlan}</Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControl margin="dense">
                    <input
                      style={{ display: 'none' }}
                      id="input-file-floor-plan"
                      multiple
                      type="file"
                      onChange={handleUpload('FLOOR_PLAN')}
                    />
                    <label htmlFor={floorPlans.length >= uploadLimits.FLOOR_PLAN || saveDisabled ? undefined : "input-file-floor-plan"}>
                      <Button {...{ component: "span" } as any}
                        variant="contained"
                        tooltip={floorPlans.length >= uploadLimits.FLOOR_PLAN ? langPropertyStock.msgFloorPlanLimit.replace('%d', uploadLimits.FLOOR_PLAN.toString()) : undefined}
                        disabled={floorPlans.length >= uploadLimits.FLOOR_PLAN || saveDisabled}
                        color="primary">{lang.actionUpload} ({`${floorPlans.length}/${uploadLimits.FLOOR_PLAN}`})</Button>
                    </label>

                  </FormControl>
                  <Typography style={{ marginLeft: 8 }}>
                    <a href="javascript:" onClick={openPopup('FLOOR_PLAN')}>{langPropertyStock.msgUploadRequirement}</a>
                  </Typography>

                  <Popover
                    open={reqPopupOpened.FLOOR_PLAN}
                    anchorEl={popupAnchor}
                    onClose={closePopup('FLOOR_PLAN')}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Typography style={{ margin: 8 }}>{format(langPropertyStock.msgUploadRequirementTemplate,
                      uploadPolicies['FLOOR_PLAN'].MAX_FILE_SIZE,
                      uploadPolicies['FLOOR_PLAN'].SUPPORT_FILE_TYPE,
                      uploadPolicies['FLOOR_PLAN'].RATIO,
                    )}</Typography>
                  </Popover>
                </div>

                <div style={{ marginTop: 8, width: '100%', height: 150, background: '#fafafa', borderRadius: 5, overflowX: 'scroll', overflowY: 'hidden' }}>
                  <DragDropContext onDragEnd={(result) => {
                    if (!result.destination) {
                      return;
                    }
                    const i1 = result.source.index, i = result.destination?.index ?? 0;
                    const oldList = [...floorPlans];
                    let newList = [];
                    if (i > i1) { //move downward
                      newList.push(...oldList.slice(0, i1)); // items after the selected item's original position
                      newList.push(...oldList.slice(i1 + 1, i + 1)); //items between the original position and the destination
                      newList.push(oldList[i1]); // selected item
                      newList.push(...oldList.slice(i + 1, oldList.length)); //items before the destination
                    } else if (i < i1) { //move upward
                      newList.push(...oldList.slice(0, i)); // items after the selected item's destination
                      newList.push(oldList[i1]); //selected item
                      newList.push(...oldList.slice(i, i1)); //items between the destination and the original position
                      newList.push(...oldList.slice(i1 + 1, oldList.length)); // items before the original position
                    } else {
                      newList = oldList;
                    }
                    setFloorPlans(newList);
                  }}>
                    <Droppable droppableId="floor-plan-container" direction="horizontal">
                      {(provided, _snapshot) => <div ref={provided.innerRef} style={{ width: 138 * floorPlans.length, height: 150, display: 'flex', alignItems: 'center'}}>
                        {floorPlans.map(({ filename }) => `${BASE_URL}/files/${filename}`).map((src, i) => (
                          <Draggable key={src} draggableId={src} index={i} isDragDisabled={saveDisabled}>
                            {(provided, _snapshot) => (
                              <div className={classes.fileItem}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() => {
                                  setFloorPlanLightBoxOpened(true);
                                  setFloorPlanLightBoxIndex(i);
                                }}
                                style={{
                                  display: 'flex',
                                  cursor: 'pointer',
                                  justifyContent: 'space-between',
                                  alignItems: 'start', marginLeft: 8, width: 260 / 2, minWidth: 260 / 2, height: 130,
                                  backgroundImage: `url("${src}")`, backgroundSize: 'cover', borderRadius: 5,
                                  boxShadow: theme.shadows[5],
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <div style={{ padding: 4, margin: 4, borderRadius: 8 }}></div>
                                {saveDisabled ? null : <div
                                  onClick={(ev) => {
                                    ev.stopPropagation();
                                    ev.preventDefault();
                                    const newList = [...floorPlans];
                                    newList.splice(i, 1);
                                    confirmDialog.confirm(lang.msgConfirmDelete, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
                                      if (confirmed) {
                                        setFloorPlans(newList);
                                      }
                                    });
                                  }}
                                  style={{ width: 23, height: 23, fontSize: 21, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontWeight: 'bolder', userSelect: 'none', cursor: 'pointer', background: 'rgba(200,0,0,0.8)', color: '#fff', padding: 4, margin: 4, borderRadius: 800 }}>
                                  <div>{'\u2013'}</div>
                                </div>}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>}
                    </Droppable>
                  </DragDropContext>
                </div>
              </Grid>

            </Grid>
          </CardContent>
        </form>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card>
        <CardHeader title={<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div>{langPropertyStock.titlePropertySummary}</div>
          <div style={{ paddingLeft: 16 }}>
            <Button disabled={isNew} onClick={() => {
              if (unsaved) {
                confirmDialog.confirm(langPropertyStock.msgPleaseSaveBeforeGenerateSummary, lang.actionConfirm, lang.actionCancel).then(confirmed => {
                  if (confirmed) {
                    onSave?.(true);
                    setOpenPropertySummaryDialog(true);
                  }
                });
              } else {
                setOpenPropertySummaryDialog(true);
              }
            }} variant="outlined" color="secondary">{langPropertyStock.actionGeneratePropertySummaryReport}</Button>
          </div>
        </div>} subheader="" />
        <Divider />
        <CardContent>
          <Grid container /*xs={12}*/ spacing={1}>
            <Grid
              item
              md={4} sm={4}
              xs={12}
            >
              <Typography>{langPropertyStock.heading}</Typography>
              <MultilineTextField
                fullWidth
                multiline
                rows="1"
                // label="Heading"
                margin="dense"
                name="headingTC"
                {...bind('headingTC')}

                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment className={classes.langAdornment} position="start">{lang.traditionalChineseAbbr}</InputAdornment>
                }}
                inputProps={{
                  style: { resize: 'vertical' }

                }}
                textLimit={1024}
              />


              <MultilineTextField
                fullWidth
                multiline rows="1"
                // label="Heading"
                margin="dense"
                name="headingSC"
                {...bind('headingSC')}

                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment className={classes.langAdornment} position="start">{lang.simplifiedChineseAbbr}</InputAdornment>
                }}
                inputProps={{
                  style: { resize: 'vertical' }

                }}
                textLimit={1024}
              />
              {/* <HandleFormHelperText
                isError={keyBooleanMap.get('headingSC')}
                errorMessage={lang.textOverLimit}
              /> */}

              <MultilineTextField
                fullWidth
                multiline rows="1"
                // label="Heading"
                margin="dense"
                name="headingEN"
                {...bind('headingEN')}
                variant="outlined"

                InputProps={{
                  startAdornment: <InputAdornment className={classes.langAdornment} position="start">{lang.englishAbbr}</InputAdornment>
                }}
                inputProps={{
                  style: { resize: 'vertical' }

                }}
                textLimit={1024}
              />
              {/* <HandleFormHelperText
                isError={keyBooleanMap.get('headingEN')}
                errorMessage={lang.textOverLimit}
              /> */}
            </Grid>
            <Grid
              item
              md={8} sm={8}
              xs={12}
            >
              <Typography>{langPropertyStock.punchline}</Typography>
              <div>
                <MultilineTextField
                  fullWidth
                  multiline rows="1"
                  // label="Heading"
                  margin="dense"
                  name="punchlineTC"
                  variant="outlined"
                  {...bind('punchlineTC')}

                  InputProps={{
                    startAdornment: <InputAdornment className={classes.langAdornment} position="start">{lang.traditionalChineseAbbr}</InputAdornment>
                  }}
                  inputProps={{
                    style: { resize: 'vertical' }

                  }}
                  textLimit={1024}
                />
                {/* <HandleFormHelperText
                  isError={keyBooleanMap.get('punchlineTC')}
                  errorMessage={lang.textOverLimit}
                /> */}

                <MultilineTextField
                  fullWidth
                  multiline rows="1"
                  // label="Heading"
                  margin="dense"
                  name="punchlineSC"
                  {...bind('punchlineSC')}

                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment className={classes.langAdornment} position="start">{lang.simplifiedChineseAbbr}</InputAdornment>
                  }}
                  inputProps={{
                    style: { resize: 'vertical' }

                  }}
                  textLimit={1024}
                />
                {/* <HandleFormHelperText
                  isError={keyBooleanMap.get('punchlineSC')}
                  errorMessage={lang.textOverLimit}
                /> */}

                <MultilineTextField
                  fullWidth
                  multiline rows="1"
                  // label="Heading"
                  margin="dense"
                  name="punchlineEN"
                  variant="outlined"
                  {...bind('punchlineEN')}

                  InputProps={{
                    startAdornment: <InputAdornment className={classes.langAdornment} position="start">{lang.englishAbbr}</InputAdornment>
                  }}
                  inputProps={{
                    style: { resize: 'vertical' }

                  }}
                  textLimit={1024}
                />
                {/* <HandleFormHelperText
                  isError={keyBooleanMap.get('punchlineEN')}
                  errorMessage={lang.textOverLimit}
                /> */}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
  );
};

PropertyFeatures.propTypes = {
  className: PropTypes.string
};

export default PropertyFeatures;
